var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: { "tab-list": _vm.tabList, "active-tab-key": _vm.activeTabKey },
      on: { tabChange: _vm.onTabChange },
      scopedSlots: _vm._u([
        {
          key: "customRender",
          fn: function(item) {
            return _c("span", {}, [_vm._v(_vm._s(_vm.$t(item.title)))])
          }
        }
      ])
    },
    [
      _c(
        "transition",
        { attrs: { name: "component-fade", mode: "out-in" } },
        [_c("keep-alive", [_c(_vm.dynamicComp, { tag: "component" })], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }