























import Vue from "vue";

export default Vue.extend({
  name: "ProductReportBottomPrice",
  components: {
    "compReport": () => import(/*webpackPrefetch: true*/"./ReportBottomPrice.vue"),
    "compUpload": () => import(/*webpackPrefetch: true*/"./UploadBottomPrice.vue"),
  },
  data() {
    return {
      activeTabKey: "report",
      tabList: [
        {
          key: "report",
          title: "lbl_report",
          comp: "compReport",
          scopedSlots: { tab: "customRender" }
        },
        {
          key: "upload",
          title: "lbl_upload",
          comp: "compUpload",
          scopedSlots: { tab: "customRender" }
        },
      ] as { key: string, comp: string, title: string, scopedSlots }[]
    };
  },
  computed: {
    dynamicComp(): string {
      return this.tabList.find(x => x.key === this.activeTabKey)?.comp || "";
    },
  },
  methods: {
    onTabChange(key: string): void {
      this.activeTabKey = key;
    }
  }
});
